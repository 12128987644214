<template>
  <ServicesDescription :isFullWidth="true" :isFooter="false" :isPageWrapper="true">
    <template #body>
      <b-nav tabs class="dflex_nowrap">
        <b-nav-item
          v-for="report in reports"
          :key="report.name"
          :to="{ name: report.address }"
          exact-active-class="active"
        >
          {{ report.name }}
        </b-nav-item>
      </b-nav>
      <router-view></router-view>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  data() {
    return {
      reports: [
        { name: 'Оценка эффективности работы', address: 'ServiceMetrics' },
        { name: 'Показатели обслуживания', address: 'EfficiencyMark' }
      ]
    };
  },

  components: {
    ServicesDescription
  }
};
</script>

<style>
.nav-item > a {
  height: 100%;
}
</style>
